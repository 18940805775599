<template>
  <BaseModal
    dataClass="definition-item-modify-modal"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
    @show-modal="clear(obj)"
  >
    <template v-slot:main>
      <div data-class="work-list" class="mx-2 my-2">
        <BaseSearchCondition
          :searchCondition="searchCondition"
          :searchConditionInfo="searchConditionInfo"
          @clear="clear(obj)"
          @search="search(obj)"
        >
        </BaseSearchCondition>
        <br />
        <!-- テーブル -->
        <BasePagingTable
          v-model="searchCondition.size"
          id="work-list-table"
          :selectMode="selectMode"
          :fields="fields"
          :items.sync="tableItems"
          :selected-items.sync="selectedItem"
          :columnInfoPage="pagingInfo.page"
          :columnInfoSize="pagingInfo.size"
          :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
          :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
          :perPage="perPage"
          @size-changed="updateSize"
        />
      </div>
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- 選択ボタン -->
      <BaseButton
        variant="primary"
        icon="check-circle"
        :columnInfo="resultControlInfo.select"
        :disabled="!oneSelected"
        @click="select"
      />
      <!-- 選択解除ボタン -->
      <BaseButton
        v-if="useUnset"
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.unselect"
        @click="unselect"
      />
      <!-- 閉じるボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.close"
        @click="cancel"
      />
    </template>
  </BaseModal>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
} from '@/common/Common.js'
import { search, clear } from './PostalCodeModal.js'
import { DELETED_KIND } from '@/common/const.js'

export default {
  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
    }
  },

  props: {
    selectMode: {
      type: String,
      default: 'single',
    },
    displayModals: {
      type: String,
      // 'one'  ->'postal-code-modal'
      // 'two'  ->'postal-code-modal-edit'
      // 'three'->'postal-code-modal-three'
      // 'four' ->'postal-code-modal-four'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.POSTAL_CODE_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 郵便番号
            type: 'text',
            id: 'postalCode',
            columnInfo: searchConditionInfo.postal_code,
            rules: [this.INPUT_TYPE_CHECK.ZIPNUMERIC],
            maxLength: 7,
          },
          {
            // 都道府県
            type: 'select',
            id: 'prefectureCode',
            columnInfo: searchConditionInfo.prefecture_code,
            options: getListOptions(this.MASTER_CODE.PREFECTURE),
            onChangeCallback: () => {},
          },
          {
            // 市区町村
            type: 'text',
            id: 'cityName',
            columnInfo: searchConditionInfo.city_name,
            maxLength: 15,
          },
        ],
      }
    },
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.POSTAL_CODE_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.POSTAL_CODE_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    search,
    clear,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        postalCode: null,
        prefectureCode: null,
        prefectureName: null,
        cityName: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.POSTAL_CODE_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('postal-code-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('postal-code-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('postal-code-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('postal-code-modal-four')
      }
      this.$bvModal.hide('area-postal-code-modal')
      if (this.selectMode == 'single') {
        this.$emit('after-close-set', this.selectedItem[0])
      } else {
        this.$emit('after-close-set', this.selectedItem)
      }
    },

    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('postal-code-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('postal-code-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('postal-code-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('postal-code-modal-four')
      }
      this.$bvModal.hide('area-postal-code-modal')
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },
  },
}
</script>
